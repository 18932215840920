.patreon-button {
    box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
    line-height: 36px !important;
    min-width: 190px;
    max-width: 190px;
    display: flex !important;
    align-items: center;
    background-color: #6366f1;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    text-align: center !important;
    border-radius: 7px;
    color: #fff;
    cursor: pointer;
    overflow-wrap: break-word;
    vertical-align: middle;
    border: 0 none #fff !important;
    font-family:
        "Quicksand",
        Helvetica,
        Century Gothic,
        sans-serif !important;
    text-decoration: none;
    text-shadow: none;
    font-weight: 700 !important;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.patreon-button:hover {
    opacity: 0.85;
    color: #f5f5f5 !important;
    text-decoration: none !important;
}


@tailwind base;
@tailwind components;
@tailwind utilities;

* {
	min-width: 0;
}

#footer {
    display: flex;
    justify-content: center; /* Center content horizontally */
    align-items: center; /* Center content vertically */
    text-align: center; /* Ensure text within is centered */
    margin-top: auto; /* Push footer to the bottom */
    width: 100%; /* Make footer full width */
}

.form-field {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}

.form-label {
    @apply text-base;
}

.form-input {
    width: 100%;
    @apply text-base;
    border-radius: 0.25rem;
    border: 1px solid #ccc;
}

@media (min-width: 768px) {
    .form-field {
        flex-direction: row;
        align-items: center;
    }

    .form-label {
        width: 20%;
        margin-bottom: 0;
        text-align: right;
        padding-right: 1rem;
    }

    .form-input {
        width: 80%;
    }
}

@media (max-width: 767px) {
    button {
        width: 100% !important;
        margin-left: 0 !important;
    }
}

#right {
    flex: 0 1 auto;
    /* width: 325px; */
    width: 50vw;
    padding: 0 12px;
    box-sizing: border-box;
    font:
        12px "M+ 1c",
        sans-serif;
    display: inline-flex;
    /* flex-direction: column; */
    background-color: #121419;
    color: white;
}

#right label {
    color: white;
}
#right textarea {
    color: white;
    background-color: #2b2a33;
    border-color: #383d4c;
}

form > textarea[name="paste"] {
    flex: 1 1 auto;
    width: 50vw;
    height: 100vh;
    margin: 0;
    border: 0;
    padding: 12px;
    box-sizing: border-box;
    background-color: black;
    color: white;
    font:
        12px "M+ 1m",
        monospace;
}

input {
    color: white !important;
    border-color: #383d4c !important;
    background-color: #2b2a33;
}
